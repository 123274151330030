<template>
    <form @submit.prevent>
        <h4>Авторизация</h4>
        <my-input
                v-focus
                v-model="post.title"
                placeholder="Введите логин"
        />
        <my-input
                v-model="post.body"
                placeholder="Введите пароль"
        />

        <my-button
                style="align-self: flex-end; margin: 15px 0 0"

                @click="auu"
        >
            AUTHORIZE
        </my-button>
    </form>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "login-form",
        data() {
            return {
                post: {
                    title: '',
                    body: '',
                },
            }
        },
        methods: {
            ...mapActions({
                auth: 'post/auth',
            }),
            auu() {
                this.auth();
                this.$emit('login');
            },
        }
    }
</script>

<style scoped>
    form {
        display: flex;
        flex-direction: column;
    }
</style>