<template>
    <nav-bar></nav-bar>
    <div class="app">
        <router-view></router-view>
    </div>
</template>

<script>
    import NavBar from "./Components/UI/NavBar";

    export default {
        components: {
            NavBar
        }
    }
</script>

<style>
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        list-style: none;
        text-decoration: none;
        -webkit-tap-highlight-color: transparent;
        outline: none;
    }
    ::-webkit-scrollbar {
        width: 0;
    }

    body {
        overscroll-behavior-y: contain;
    }
    .app {
        margin-top: 50px;
        padding: 0 0 20px;
    }

    a {
        color: #333333;
    }
    @media (max-width: 768px) {
        .app {
            /*padding: 8px;*/
        }
    }
</style>