<template>
    <div v-if="posts.length > 0">
        <h3 style="text-align: center;">Список постов</h3>
        <transition-group name="post-list">
            <post-item
                    v-for="post in posts"
                    :post="post"
                    :id="post.id"
                    :key="post.id"
                    @remove="$emit('remove', post)"
            >
            </post-item>
        </transition-group>

    </div>
    <h2 v-else style="color: brown" >
        Список постов пуст !!!
    </h2>
</template>

<script>
    import PostItem from "./PostItem";

    export default {
        name: "post-list",
        components: {PostItem},
        props: {
            posts: {
                type: Array,
                required: true,
            }
        }

    }
</script>

<style scoped>
    .post-list-item {
        display: inline-block;
        margin-right: 10px;
    }
    .post-list-enter-active,
    .post-list-leave-active {
        transition: all 0.6s ease;
    }
    .post-list-enter-from,
    .post-list-leave-to {
        opacity: 0;
        /*transform: translateX(130px);*/
        transform: scale(1, 0);
        transition: all 0.6s ease;

    }
    .post-list-move {
        transition: transform 0.8s ease;
    }
</style>