import MyButton from "./MyButton";
import MyInput from "./MyInput";
import MyDialog from "./MyDialog";
import PostForm from "../PostForm";
import PostList from "../PostList";
import LoginForm from "../LoginForm";

export default [
    MyButton,
    MyInput,
    MyDialog,
    PostForm,
    PostList,
    LoginForm
]