<template>
    <div class="navbar">
        <div><strong>VUE 3</strong></div>
        <div class="navbar__btns">
            <my-button @click="()=>{
                au();
                $router.push(`/store`)
            } " v-if="!isAuth"> Авторизация</my-button>
            <my-button class="q" v-if="isAuth" @click="$router.push(`/`)">MAIN</my-button>
<!--            <my-button class="q" @click="$router.push(`/posts`)">POSTS</my-button>-->
            <my-button class="q" v-if="isAuth" @click="$router.push(`/store`)">POSTS</my-button>
<!--            <my-button class="q" @click="$router.push(`/about`)">ABOUT</my-button>-->
        </div>
    </div>
    <my-dialog v-model:show="dialogVisible">

        <login-form @login="clz"/>

    </my-dialog>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "NavBar",
        data(){
            return {
                dialogVisible: false,
            }
        },

        computed: {
            ...mapState({
                isAuth: state => state.post.isAuth,
            }),
        },
        methods:{
            au() {
                this.dialogVisible = true;

            },
            clz() {
                this.dialogVisible = false;
            },
    }
    }
</script>

<style scoped>
    .navbar {
        position: fixed;
        top: 0;
        width: 100%;
        height: 50px;
        /*background-color: lightgrey;*/
        background-color: white;
        box-shadow: 0 2px 4px grey;
        display: flex;
        align-items: center;
        padding: 0 15px;
        z-index: 9999;
    }
    .navbar__btns{
        margin-left: auto;
    }
    button{
        margin-right: 20px;
    }
    button:last-child{
        margin-right: 0;
    }
    @media (max-width: 768px) {
        button{
            margin-right: 10px;
        }
        .navbar{
            padding: 0 8px;
        }
    }
</style>