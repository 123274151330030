<template>
    <div>
        <h1 style="text-align: center;">Страница с постами</h1>

        <my-input
                v-focus
                v-model="searchQuery"
                placeholder="Поиск..."
        />
        <div class="app__btns">

            <my-button
                    @click="showDialog"

            >
                ADD post
            </my-button>
            <my-select
                    v-model="selectedSort"
                    :options="sortOptions"
            />
        </div>

        <!--        <my-button-->
        <!--                @click="fetchPosts"-->
        <!--        >-->
        <!--            Получить посты-->
        <!--        </my-button>-->
        <my-dialog v-model:show="dialogVisible">
            <post-form
                    @create="createPost"
            />
        </my-dialog>

        <post-list
                :posts="sortedAndSearchedPosts"
                @remove="removePost"
                v-if="!isPostloading"
        />
        <div style="text-align: center; color:red;" v-else>LOADING...</div>
        <!--        <div ref="observer" class="observer"></div>-->
        <div v-intersection="loadMorePosts" class="observer"></div>
        <!--        <div class="page__wrapper">-->
        <!--            <div-->
        <!--                    v-for="pageNumber in totalPages"-->
        <!--                    :key="pageNumber"-->
        <!--                    class="page"-->
        <!--                    :class="{-->
        <!--                        'current-page': page === pageNumber-->
        <!--                    }"-->
        <!--                    @click="changePage(pageNumber)"-->
        <!--            >-->
        <!--                {{pageNumber}}-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script>
    import axios from 'axios';
    import MySelect from "@/Components/UI/MySelect";

    export default {
        name: "App",

        components: {
            MySelect,
        },

        data() {
            return {
                posts: [],
                dialogVisible: false,
                isPostloading: false,
                selectedSort: '',
                sortOptions: [
                    // {value: 'id', name: 'По ID'},
                    {value: 'title', name: 'по названию'},
                    {value: 'body', name: 'по содержимому'},
                ],
                searchQuery: '',
                page: 1,
                limit: 10,
                totalPages: 0,
                r: 0,
            }
        },
        methods: {
            createPost(post) {
                this.posts.push(post);
                this.dialogVisible = false;
                console.log(post)
            },
            removePost(post) {
                this.posts = this.posts.filter(p => p.id !== post.id)
            },
            showDialog() {
                this.dialogVisible = true;
            },
            // changePage(pageNumber) {
            //     this.page = pageNumber;
            //     // this.fetchPosts();
            // },
            async fetchPosts() {
                try {
                    this.isPostloading = true;
                    // const response = await axios.get('https://jsonplaceholder.typicode.com/posts?_limit=10');
                    const response = await axios.get('https://jsonplaceholder.typicode.com/posts', {
                        params: {
                            _page: this.page,
                            _limit: this.limit
                        }
                    });
                    this.totalPages = Math.ceil(response.headers['x-total-count'] / this.limit);
                    this.posts = response.data;
                } catch (e) {
                    alert('Ошибка')
                } finally {
                    this.isPostloading = false;
                }
            },
            async loadMorePosts() {
                try {
                    this.page += 1;
                    // const response = await axios.get('https://jsonplaceholder.typicode.com/posts?_limit=10');
                    const response = await axios.get('https://jsonplaceholder.typicode.com/posts', {
                        params: {
                            _page: this.page,
                            _limit: this.limit
                        }
                    });
                    this.totalPages = Math.ceil(response.headers['x-total-count'] / this.limit);
                    this.posts = [...this.posts, ...response.data];
                } catch (e) {
                    alert('Ошибка')
                } finally {
                }
            },

        },
        mounted() {
            this.fetchPosts();
            // const options = {
            //     rootMargin: '0px',
            //     threshold: 1.0
            // };
            // const callback = (entries, observer) => {
            //     if (entries[0].isIntersecting && this.page < this.totalPages) {
            //         this.loadMorePosts();
            //     }
            // };
            // const observer = new IntersectionObserver(callback, options);
            // observer.observe(this.$refs.observer)
        },
        computed: {
            sortedPosts() {
                return [...this.posts].sort((post1, post2) => post1[this.selectedSort]?.localeCompare(post2[this.selectedSort]))
            },
            sortedAndSearchedPosts() {
                return this.sortedPosts.filter(post => post.title.toLowerCase().includes(this.searchQuery.toLowerCase()))
            }
        },
        watch: {
            // page() {
            //     this.fetchPosts();
            // }
        }
    }
</script>

<style scoped>

    .app__btns {
        margin: 15px 0;
        display: flex;
        justify-content: space-between;
    }

    .page__wrapper {
        display: flex;
        margin-top: 15px;
    }

    .page {
        border: 1px solid black;
        padding: 10px;
        cursor: pointer;
        user-select: none;
    }

    .current-page {
        border: 2px solid teal;
        background: #d8ffec;
    }

    .observer {

    }

</style>