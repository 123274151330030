import axios from "axios";

export const postModule = {
    state: () => ({
        posts: [],
        isPostsLoading: false,
        selectedSort: '',
        searchQuery: '',
        page: 1,
        limit: 10,
        totalPages: 0,
        sortOptions: [
            {value: 'id', name: 'по id'},
            {value: 'title', name: 'по названию'},
            // {value: 'body', name: 'по содержимому'},
        ],
        isAuth: false,
        loadContent: false,

    }),
    getters: {
        sortedPosts(state) {
            if (state.selectedSort !== 'id') {
                return [...state.posts].sort((post1, post2) => post1[state.selectedSort]?.localeCompare(post2[state.selectedSort]));
            } else {
                return [...state.posts].sort((post1, post2) => post1[state.selectedSort] - post2[state.selectedSort]);
            }
        },
        sortedAndSearchedPosts(state, getters) {
            return getters.sortedPosts.filter(post => post.title.toLowerCase().includes(state.searchQuery.toLowerCase()))
        },

    },
    mutations: {
        setPosts(state, posts) {
            state.posts = posts;
        },
        setLoading(state, bool) {
            state.isPostsLoading = bool
        },
        setPage(state, page) {
            state.page = page
        },
        setSelectedSort(state, selectedSort) {
            state.selectedSort = selectedSort
        },
        setTotalPages(state, totalPages) {
            state.totalPages = totalPages
        },
        setSearchQuery(state, searchQuery) {
            state.searchQuery = searchQuery
        },
        dll(state, post) {
            state.posts = state.posts.filter(p => p.id !== post.id)
        },
        autth(state) {
            state.isAuth = true
        },
        loadFirstContent(state) {
            state.loadContent = true
        },
        postMutation(state, obj) {
            let index = state.posts.findIndex(post => post.id === obj.id);
            state.posts[index] = obj;
        },
    },
    actions: {
        async fetchPosts({state, commit}) {
            try {
                commit('setLoading', true);
                const response = await axios.get('https://jsonplaceholder.typicode.com/posts', {
                    params: {
                        _page: state.page,
                        _limit: state.limit
                    }
                });
                commit('setTotalPages', Math.ceil(response.headers['x-total-count'] / state.limit));
                commit('setPosts', response.data)
            } catch (e) {
                // console.log(e)
            } finally {
                commit('setLoading', false);
            }
        },
        async loadMorePosts({state, commit}) {
            try {
                commit('setPage', state.page + 1);
                const response = await axios.get('https://jsonplaceholder.typicode.com/posts', {
                    params: {
                        _page: state.page,
                        _limit: state.limit
                    }
                });
                commit('setTotalPages', Math.ceil(response.headers['x-total-count'] / state.limit))
                commit('setPosts', [...state.posts, ...response.data]);
            } catch (e) {
                // console.log(e)
            }
        },
        dellPost(state, post) {
            state.commit('dll', post);
        },
        auth(state) {
            state.commit('autth');
        },
        lfc(state) {
            state.commit('loadFirstContent');
        },
        newPostData(state, obj){
            state.commit('postMutation', obj)
        },

    },
    namespaced: true
}
