<template>
    <div>
        <router-link to="/store">First VUE project</router-link>
    </div>
</template>

<script>
    export default {
        name: "Main",

    }
</script>

<style scoped>
    div {
        position: fixed;
        margin: -20px;
        display: flex;
        inset: 70px 0 0;
        background: #333;
        align-items: center;
        justify-content: center;
        text-align: center;
        user-select: none;
    }

    a {
        color: white;
        font-size: 60px;
        cursor: pointer;
    }
</style>