<template>
    <div class="appcl">
        <!--        <h3 style="text-align: center;">- {{isAuth ? 'ВЫ авторизованы' : 'Авторизуйтесь'}} -</h3>-->
        <h1 v-if="!isAuth" style="text-align: center;">- Авторизуйтесь -</h1>

        <h1 style="text-align: center;" v-if="isAuth">Страница с постами</h1>
        <div>

        </div>
        <div v-if="isAuth"
             style="position: sticky; top: 55px; background: white; padding: 0 0 10px; display:flex; z-index: 9999">

            <my-button
                    @click="showDialog"
                    style="margin: 15px 10px 0 0; width: 150px; height: 37px;"
            > ADD post
            </my-button>

            <my-input

                    :model-value="searchQuery"
                    @update:model-value="setSearchQuery"
                    placeholder="Быстрый поиск..."
                    v-if="isAuth"

            />
            <my-select
                    :model-value="selectedSort"
                    @update:model-value="setSelectedSort"
                    :options="sortOptions"
                    style="border: 1px solid teal;margin-left: 10px; margin-top: 15px;background: none"
            />


        </div>


        <my-dialog v-model:show="dialogVisible">

            <post-form v-if="pf" @create="createPost"/>

        </my-dialog>

        <post-list
                :posts="sortedAndSearchedPosts"
                @remove="removePost"
                v-if="!isPostsLoading, isAuth"
        />
        <div v-if="isPostsLoading" style="text-align: center; color:red;">LOADING...</div>

        <div v-intersection="loadMorePosts" class="observer" v-if="isAuth"></div>

    </div>


</template>

<script>
    import MySelect from "@/Components/UI/MySelect";
    import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

    export default {
        name: "App",

        components: {
            MySelect,
        },

        data() {
            return {
                dialogVisible: false,
                pf: false,
            }
        },
        methods: {
            ...mapMutations({
                setPage: 'post/setPage',
                setSearchQuery: 'post/setSearchQuery',
                setSelectedSort: 'post/setSelectedSort',
            }),
            ...mapActions({
                loadMorePosts: 'post/loadMorePosts',
                fetchPosts: 'post/fetchPosts',
                dellPost: 'post/dellPost',
                auth: 'post/auth',
                lfc: 'post/lfc',
            }),
            createPost(post) {
                this.posts.push(post);
                this.dialogVisible = false;
            },
            removePost(post) {
                this.dellPost(post);
            },
            showDialog() {
                this.pf = true;
                this.dialogVisible = true;
            },

            loadP() {
                this.lfc();
            },

        },
        mounted() {
            if (!this.loadContent) {
                this.fetchPosts();
                this.loadP();
            }
        },
        computed: {
            ...mapState({
                posts: state => state.post.posts,
                isPostsLoading: state => state.post.isPostsLoading,
                selectedSort: state => state.post.selectedSort,
                searchQuery: state => state.post.searchQuery,
                page: state => state.post.page,
                limit: state => state.post.limit,
                totalPages: state => state.post.totalPages,
                sortOptions: state => state.post.sortOptions,
                isAuth: state => state.post.isAuth,
                loadContent: state => state.post.loadContent,
            }),
            ...mapGetters({
                sortedPosts: 'post/sortedPosts',
                sortedAndSearchedPosts: 'post/sortedAndSearchedPosts',
            })
        },
        watch: {}
    }
</script>

<style scoped>

    .app__btns {
        margin: 15px 0;
        display: flex;
        justify-content: space-between;
    }

    .page__wrapper {
        display: flex;
        margin-top: 15px;
    }

    .page {
        border: 1px solid black;
        padding: 10px;
        cursor: pointer;
        user-select: none;
    }

    .current-page {
        border: 2px solid teal;
        background: #d8ffec;
    }

    .observer {

    }

    .appcl {
        width: 98%;
        max-width: 1200px;
        margin: 0 auto;
    }

</style>