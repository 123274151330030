import Main from "../pages/Main";
import {createRouter, createWebHistory} from "vue-router";
import PostPage from "../pages/PostPage";
import About from "../pages/About";
import PostIdPage from "../pages/PostIdPage";
import PostPageWishStore from "../pages/PostPageWishStore";


const routes = [
    {
        path: '/',
        component: Main
    },
    // {
    //     path: '/posts',
    //     component: PostPage
    // },
    {
        path: '/about',
        component: About
    },
    {
        path: '/store/:id',
        component: PostIdPage
    },
    {
        path: '/store',
        component: PostPageWishStore
    },
];

const router = createRouter({ routes, history: createWebHistory(process.env.BASE_URL) });

export default router;