<template>
    <div class="dialog" v-if="show" @click="hideDialog">
        <div class="dialog__content" @click.stop>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import toggleMixin from "../../mixins/toggleMixin";

    export default {
        name: "my-dialog",

        mixins: [toggleMixin],
        mounted() {
        }
    }
</script>

<style scoped>

    .dialog {
        inset: 0;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        display: flex;
        z-index: 999999;
    }

    .dialog__content{
        margin: auto;
        background: white;
        border-radius: 12px;
        min-height: 40px;
        min-width: 300px;
        padding: 20px;
    }
</style>