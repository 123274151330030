import {createStore} from "vuex";
import {postModule} from "./postModule";

export default createStore({

    state: {
        // isAuth: false,
        likes: 44,
    },
    modules: {
        post: postModule
    }
})