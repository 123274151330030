<template>
    <form @submit.prevent>
        <h4>Создание поста</h4>
        <my-input
                v-focus
                v-model="post.title"
                placeholder="Название"
        />
        <my-input
                v-model="post.body"
                placeholder="Описание"
        />

        <my-button
                style="align-self: flex-end; margin: 15px 0 0"

                @click="createPost"
        >
            Create
        </my-button>
    </form>
</template>

<script>
    export default {
        name: "post-form",
        data() {
            return {
                post: {
                    id:'',
                    title: '',
                    body: '',
                }
            }
        },
        methods: {
            createPost() {
                this.post.id = Date.now();
                this.$emit('create', this.post);
                this.post = {
                    title: '',
                    body: '',
                }
            }
        }
    }
</script>

<style scoped>
    form {
        display: flex;
        flex-direction: column;
    }
</style>