<template>
    <button class="btn">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        name: "my-button"
    }
</script>

<style scoped>
    .btn {
        border: 1px solid teal;
        padding: 10px 15px;
        background: none;
        color: teal;
        cursor: pointer;
        user-select: none;
    }
    .btn:active{
        transform: scale(0.97);
    }
    @media (max-width: 768px) {
        .btn{
            padding: 5px 8px;
        }
    }
</style>