<template>
    <div>
        <h1 style="text-align: center;">i am a JS programist !!!</h1>
    </div>
</template>

<script>
    export default {
        name: "About"
    }
</script>

<style scoped>
    div {
        position: fixed;
        margin: -20px;
        display: flex;
        inset: 70px 0 0;
        background: #333;
        color: white;
        align-items: center;
        justify-content: center;
        text-align: center;
        user-select: none;
    }
</style>