<template>
    <div class="post">
        <div>
            <my-button
                    @click="$router.push(`/store`)"
                    style="margin-bottom: 20px;"
            >
                Back
            </my-button>

            <!--            <h4 style="text-align: center;">Страница поста с ID = {{$route.params.id}}</h4>-->
        </div>
        <div>
            <div style="border: 2px solid teal;padding: 15px">
                <div v-for=" (value, name) in postt[0]">
                    <div style="margin-bottom: 15px;"><strong>{{ name }}: </strong>{{ value }}</div>
                </div>
            </div>
            <div style="margin-top: 20px; text-align: right">
                <my-button
                        style="color: dodgerblue; border-color: dodgerblue"
                        @click="()=>{
                            dialogVisible = true;
                            lll();
                        }"
                >
                    Edit

                </my-button>

                <my-button
                        @click="()=>{
                            removePost(postt);
                            $router.push(`/store`)
                        }"
                        style="color: red; border-color: red; margin-left: 15px;"
                >
                    Delete

                </my-button>

                <my-dialog v-model:show="dialogVisible">

                    <div v-for=" (value, name) in pop">
                        <div v-if="name !== 'id'" style="margin-bottom: 15px;">
                            <p style="text-align: left;"><strong>{{ name }}: </strong></p>
                            <textarea
                                    name=""
                                    id=""
                                    rows="7"
                                    style="width: 100%; height: 70px; padding: 5px 15px;resize: none;"
                                    v-model="pop.[name]"
                            >

                        {{pop.[name]}}

                    </textarea>

                        </div>
                    </div>

                    <my-button
                            @click="save"
                    >
                        SAVE
                    </my-button>

                </my-dialog>
            </div>
        </div>

    </div>


</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "PostIdPage",
        data() {
            return {
                postt: '',
                dialogVisible: false,
                pop: {},
            }
        },

        methods: {
            ...mapActions({
                dellPost: 'post/dellPost',
                newPostData: 'post/newPostData',
            }),
            ltt(arg) {
                this.postt = this.sortedAndSearchedPosts.filter(p => p.id === Number(arg));
            },
            lll() {
                for (let key in this.postt[0]) {
                    this.pop.[key] = this.postt[0].[key];
                }
            },
            removePost(arg) {
                this.dellPost(arg[0]);
            },
            save() {
                this.newPostData(this.pop);
                this.dialogVisible = false;
                this.ltt(this.$route.params.id);
                this.pop = {};
            },
        },

        computed: {
            ...mapGetters({
                sortedAndSearchedPosts: 'post/sortedAndSearchedPosts',
            }),
        },

        mounted() {
            this.ltt(this.$route.params.id);
        },

    }
</script>

<style scoped>
    .post {
        font-size: 20px;
        letter-spacing: 1px;
        width: 98%;
        max-width: 1200px;
        margin: 60px auto 0;
    }
</style>