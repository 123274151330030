<template>
    <div class="post">
        <div>
<!--            <div>{{$store.state.likes}}</div>-->
            <div><strong>id: </strong>{{post.id}}</div>
            <div><strong>Название: </strong>{{post.title}}</div>
<!--            <div><strong>Описание: </strong>{{post.body}}</div>-->
        </div>
        <div class="post__btns">

            <my-button
            style="
            color: dodgerblue;
            border-color: dodgerblue;"
            @click="$router.push(`/store/${post.id}`)"
            >
                Open
            </my-button>
            <my-button
            style="color: brown; border-color: brown"
            @click="$emit('remove', post)"
            >
                Delete
            </my-button>
        </div>

    </div>
</template>

<script>
    export default {
        name: "PostItem",
        props: {
            post: {
                type: Object,
                required: true,
            }
        }
    }
</script>

<style scoped>
    .post {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        padding: 15px;
        border: 2px solid teal;
    }
    .post__btns{
        display: flex;
    }
    button:first-child{
        margin: 0 20px;
    }
    @media (max-width: 768px) {
        button:first-child{
            margin: 0 0 10px 0;
        }
        .post__btns{
            flex-direction: column;
            margin: 0 0 0 15px;
        }
        .post {
            margin-top: 8px;
        }
    }
</style>