<template>
    <select name="" id="" v-model="modelValue" @change="changeOption">
        <option disabled value="">Сортировать</option>
        <option
                :value="option.value"
                v-for="option in options"
                :key="option.value"
        >
            {{option.name}}
        </option>
    </select>
</template>

<script>
    export default {
        name: "my-select",
        props: {
            modelValue: {
                type: String,
            },
            options: {
                type: Array,
                default: ()=>[]
            }
        },
        methods: {
            changeOption(event) {
                this.$emit('update:modelValue', event.target.value)
            }
        }
    }
</script>

<style scoped>

</style>